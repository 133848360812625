import { defineStore } from 'pinia'
import { ref } from 'vue'
import { stringContains } from '@/functions'

type SocialLink = string | undefined

export const useAppStore = defineStore('App', () => {
  const COMPANY_NAME = import.meta.env.VITE_COMPANY_NAME ?? ''

  const about = ref<About>()
  const selectedLanguage = ref<'en' | 'id'>('id')

  const setAbout = (data: About) => {
    about.value = data
    setLinks()
  }

  const domainsForFacebook = ['facebook.com', 'fb.com', 'fb.me']
  const domainsForYoutube = ['youtube.com', 'youtu.be']
  const domainsForInstagram = ['instagram.com', 'instagr.am']
  const linkFacebook = ref<SocialLink>()
  const linkYoutube = ref<SocialLink>()
  const linkInstagram = ref<SocialLink>()
  const setLinks = () => {
    const links = (about.value?.link ?? '').split(' ') // split link by space
    linkFacebook.value = links.find(link => stringContains(link, domainsForFacebook))
    linkYoutube.value = links.find(link => stringContains(link, domainsForYoutube))
    linkInstagram.value = links.find(link => stringContains(link, domainsForInstagram))
  }

  return {
    COMPANY_NAME,
    about,
    setAbout,
    linkFacebook,
    linkYoutube,
    linkInstagram,
    selectedLanguage,
  }
})